<template>
  <div class="inteBox">
    <intNavheader activeNameCheck="six"></intNavheader>
    <div class="titleTopLine"></div>
    <div class="titleTopCenter noflex-l-s">
      <div class="titleTopCenterLeft">
        <waitNumber :waitDataTotalNumber='waitDataTotal'></waitNumber>
      </div>
      <div class="titleTopCenterCenter">
        <div class="titleTopCenterCenterTop">
          <el-tabs class="Content1Title" v-model="activeName">
            <el-tab-pane label="我的转诊" name="1">
              <myReferral></myReferral>
            </el-tab-pane>
            <el-tab-pane label="接诊管理" name="2">
              <myReception></myReception>
            </el-tab-pane>
          </el-tabs>
        </div>
      </div>
    </div>
    <div class="titleTopLine"></div>
  </div>
</template>
<script>
import intNavheader from "../../components/intNavheader";
import waitNumber from "../../components/waitNumber";
import myReferral from "./myReferral";
import myReception from "./myReception";
import { patientMain } from "../../components/patienTmanagement/patienTmanagement";
export default {
  components: {
    intNavheader,
    waitNumber,
    myReferral,
    myReception
  },
  data() {
    var drugpatient = new patientMain(this.TokenClient, this.Services.Drug);
    return {
      activeName: "1",
      waitDataTotal: 0,
      drugpatientDomain: drugpatient,
    };
  },
  mounted(){
    this.gettWaitData()
  },
  methods:{
    gettWaitData() {
      var _this = this;
      _this.waitDataTotal = 0;
      _this.drugpatientDomain.getMZIndex(
        function (data) {
          _this.waitDataTotal = data.data.waitAuditCount;
        },
        function (error) {
          _this.waitDataTotal = 0;
        }
      );
    },
  }
};
</script>
<style scoped>
.pageBox {
  text-align: center;
  margin-top: 10px;
}

.inteBox {
  margin: 0;
  padding: 0;
  background-color: #f0f2f5;
}

.el-tabs__item {
  height: 60px;
  color: white !important;
  text-align: center;
  padding-top: 10px;
  font-size: 25px !important;
  background-color: #00afb5 !important;
  border: none !important;
}

.is-active {
  color: white !important;
  background-color: #00afb5 !important;
}

::v-deep .el-tabs__nav-wrap::after {
  background-color: #ffffff !important;
}


.titleTopLine {
  width: 100%;
  height: 12px;
}

.titleTopCenter {
  width: 100%;
  /* height: 100; */
  display: flex;
}

.titleTopCenterLeft {
  width: 15%;
  background-color: #ffffff;
  /* padding-top: 30px; */
  /* max-height: 750px; */
  overflow: auto;
}

.titleTopCenterCenter {
  width: 80%;
  border-radius: 6px;
  height: calc(100vh - 64px - 24px);
  overflow: auto;
}

.titleTopCenterCenterTop {
  background-color: #ffffff;
  padding: 0 40px;
  /* padding-top: 10px;
  padding-bottom: 10px; */
  height: 100%;
}

.noflex-l-s {
  display: flex;
  justify-content: space-around;
}

.Content1Title {
  padding: 20px 0;
  padding-left: 0;
  font-size: 18px;
  height: calc(100% - 20px);
  color: #333333;
  padding-bottom: 0;
  text-align: left;
}


::v-deep .el-pagination {
  padding: 10px 5px;
}
</style>
