<template>
  <div class="personalIndex xi">
    <div class="personal-content">
      <div class="personal-box clearfix">
        <div class="center-content">
          <div class="iq-card-body" style="margin-top: 20px">
            <div class="table-top">
              <div class="iq-search-bar">
                <el-form :model="searchForm" :inline="true" class="searchbox">
                  <el-form-item>
                    <el-date-picker v-model="searchForm.datepicker" type="daterange" range-separator="至"
                      start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd"
                      :picker-options="pickerOptions0" size="small" style="margin-right: 10px"> </el-date-picker>
                  </el-form-item>
                  <el-form-item>
                    <el-input v-model="searchForm.keyWord" type="text" size="small" placeholder="请输入姓名/手机号搜索"
                      @keyup.enter.native="SearchInputKeydown" clearable> </el-input>
                  </el-form-item>
                  <el-form-item>
                    <el-button type="primary" size="small" @click="search()">搜索</el-button>
                    <el-button type="primary" size="small" @click="AddReferralBtn()" plain>新增 </el-button>
                  </el-form-item>
                </el-form>
              </div>
            </div>
            <el-tabs v-model="state" @tab-click="handleClick">
              <el-tab-pane :label="item.labelName" :name="item.state" v-for="(item, index) in tabList" :key="index">
                <el-table :data="referraltableData" stripe style="width: 100%">
                  <el-table-column label="姓名" width="100">
                    <template slot-scope="scope">
                      <p>{{ scope.row.name }}<span style="color: red" v-if="scope.row.severity == 1">(急)</span></p>
                    </template>
                  </el-table-column>
                  <el-table-column prop="phone" label="手机号" width="120px"> </el-table-column>
                  <el-table-column prop="diagnosis" label="初步诊断"> </el-table-column>
                  <el-table-column label="接诊医生" width="250">
                    <template slot-scope="scope">
                      <p>
                        {{ scope.row.receptionName }}<span v-if="scope.row.receptionPhone">【{{ scope.row.receptionPhone
                          }}】</span>
                      </p>
                    </template>
                  </el-table-column>
                  <el-table-column prop="receptionHospitalName" label="接诊医院"> </el-table-column>
                  <el-table-column prop="receptionDermant" label="接诊部门"> </el-table-column>
                  <el-table-column prop="referralTime" label="转诊时间" width="150px">
                    <template slot-scope="scope">
                      <p>{{ scope.row.referralTime | timefilters }}</p>
                    </template>
                  </el-table-column>
                  <el-table-column prop="state" label="状态" :formatter="ReferralState" width="100"> </el-table-column>
                  <el-table-column fixed="right" label="操作" :width="tableWidth">
                    <template slot-scope="scope">
                      <el-button v-if="scope.row.state == '-1'" type="primary" size="mini"
                        @click="ReferralBtn(scope.row)" plain>转诊</el-button>
                      <el-button type="warning" v-if="scope.row.state == '-1'" size="mini"
                        @click="EditReferralBtn(scope.row)" plain>编辑</el-button>
                      <el-button type="warning" size="mini" @click="toDetail(scope.row,'referral')" plain>详情</el-button>
                      <el-button v-if="scope.row.state == '0'" type="danger" size="mini" @click="RemoveUndo(scope.row)"
                        plain>撤回</el-button>
                      <el-button v-if="scope.row.state == '-1' || scope.row.state == '11'" type="danger" size="mini"
                        @click="RemovePost(scope.row)" plain>删除 </el-button>
                    </template>
                  </el-table-column>
                </el-table>
              </el-tab-pane>
            </el-tabs>
            <el-pagination @current-change="ChangePage" :page-size="PageSize" :current-page="PageIndex"
              :total="DataTotal" layout="total, prev, pager, next, jumper"></el-pagination>
          </div>
        </div>
      </div>
    </div>
    <!--    转诊弹框    -->
    <el-dialog title="转诊处理" :visible.sync="referraldialog">
      <el-form :model="referralform" ref="referralform" :rules="rules">
        <el-form-item label="是否加急" :label-width="formLabelWidth">
          <el-switch :active-value="1" :inactive-value="0" v-model="referralform.severity" active-color="#13ce66">
          </el-switch>
        </el-form-item>
        <el-form-item label="转诊原因" :label-width="formLabelWidth" prop="reason">
                    <el-select style="width: 100%;" v-model="referralform.reason" placeholder="请选择转诊原因">
                    <el-option label="病情疑难复杂不能明确诊断，需要进一步诊治" value="病情疑难复杂不能明确诊断，需要进一步诊治"></el-option>
                    <el-option label="限于医疗机构的诊疗水平、技术能力或者设备条件等，不具备诊治能力" value="限于医疗机构的诊疗水平、技术能力或者设备条件等，不具备诊治能力"></el-option>
                    <el-option label="不具备相应的医疗技术临床应用资质或者手术资质" value="不具备相应的医疗技术临床应用资质或者手术资质"></el-option>
                    <el-option label="诊治疾病超出医疗机构执业登记的诊疗科目" value="诊治疾病超出医疗机构执业登记的诊疗科目"></el-option>
                    <el-option label="传染病按照有关规定需转入指定医疗机构治疗" value="传染病按照有关规定需转入指定医疗机构治疗"></el-option>
                    <el-option label="卫生健康行政部门规定的其他情形" value="卫生健康行政部门规定的其他情形"></el-option>
                    </el-select>
                </el-form-item>   
        <el-form-item label="" :label-width="formLabelWidth">
          <el-input type="textarea" :rows="2" placeholder="请输入内容" v-model="referralform.content"
            style="margin-top: 15px">
          </el-input>
        </el-form-item>
        <el-form-item label="" :label-width="formLabelWidth">
          <el-radio-group v-model="referralType" @change="selectReferralType">
            <el-radio :label="1">内部</el-radio>
            <el-radio :label="2">合作医院</el-radio> </el-radio-group>
          <!-- <el-checkbox style="margin-left: 15px" v-model="referralform.green" @change="checkboxChange">直接转诊到该医院的绿色通道</el-checkbox> -->
        </el-form-item>
        <el-form-item label="接诊医院" :label-width="formLabelWidth" prop="hospitalId"
          v-if="referralType == 2 && !referralform.green">
          <el-select v-model="referralform.hospitalId" placeholder="请选择接诊医院" @change="selectHospital">
            <el-option v-for="item in HospitalData" :label="item.name" :value="item.id" :key="item.index">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="接诊部门" prop="departmentId" :label-width="formLabelWidth" v-if="!referralform.green">
          <el-cascader ref="myCascader" :options="DepartmentData" :props="{
            expandTrigger: 'hover',
            emitPath: false,
            multiple: false,
            checkStrictly: true,
            value: 'id',
            label: 'name',
            children: 'childrens',
          }" v-model="referralform.departmentId" @change="selectDepartment" :disabled="disdepartment">
          </el-cascader>
        </el-form-item>
        <!--<el-form-item label="接诊部门" :label-width="formLabelWidth">
                    <el-select v-model="referralform.departmentId" placeholder="请选择接诊医院" >
                        <el-option v-for="item in DepartmentData"
                                   :label="item.name"
                                   :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>-->
        <el-form-item label="接诊医生" :label-width="formLabelWidth" prop="doctorId" v-if="!referralform.green">
          <el-select v-model="referralform.doctorId" placeholder="请选择接诊医生">
            <el-option v-for="item in DoctorData" :label="item.userName" :value="item.id" :key="item.index">
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="cancelDialog('referralform')">取 消</el-button>
        <el-button type="primary" @click="SaveUpload('referralform')">确 定</el-button>
      </div>
    </el-dialog>
    <!--    编辑弹窗  -->
    <el-dialog :title="ReferralDrawerTitle" :visible.sync="AddReferralDrawer">
      <div class="addreferral">
        <el-form ref="AddReferralform" :rules="rules" :model="AddReferralform" label-width="100px">
          <el-row>
            <el-col :span="12">
              <el-form-item label="姓名" prop="name">
                <el-input v-model="AddReferralform.name"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="性别">
                <el-radio v-model="AddReferralform.sex" label="1">男</el-radio>
                <el-radio v-model="AddReferralform.sex" label="0">女</el-radio>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="年龄" prop="age">
                <el-input v-model="AddReferralform.age"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="联系电话" prop="phone">
                <el-input v-model="AddReferralform.phone"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="病情程度">
                <el-select v-model="AddReferralform.degree" placeholder="请选择病情程度" style="width: 100%">
                  <el-option label="轻度" value="1"></el-option>
                  <el-option label="中度" value="2"></el-option>
                  <el-option label="重度" value="3"></el-option>
                  <el-option label="严重" value="4"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="身份证号" prop="identity">
                <el-input v-model="AddReferralform.identity"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="初步诊断" prop="diagnosis">
                <el-input v-model="AddReferralform.diagnosis"></el-input>
              </el-form-item>
            </el-col>
            <!-- <el-col :span="24">
              <el-form-item label="备注">
                <el-input type="textarea" :rows="2" placeholder="请输入备注" v-model="AddReferralform.introduction">
                </el-input> -->
                <!-- <quill-editor v-model="AddReferralform.introduction" ref="myQuillEditor" :content="AddReferralform.introduction" :options="editorOption" @change="onEditorChange($event)" /> -->
              <!-- </el-form-item> -->
            <!-- </el-col> -->
          </el-row>
          <el-form-item>
            <el-button type="primary" @click="SaveReferralform('AddReferralform')">保存</el-button>
            <el-button @click="cancelDialog('AddReferralform')">取消</el-button>
          </el-form-item>
          <!--<tinymce-editor ref="editor" v-model="value"></tinymce-editor>-->
        </el-form>
      </div>
    </el-dialog>
    <!--    新增转诊    -->
    <el-dialog title="患者转诊" :visible.sync="allDrawer">
      <div class="addreferral">
        <el-form ref="AddReferralform" :rules="rules" :model="AddReferralform" label-width="100px">
          <el-row>
            <el-col :span="12">
              <el-form-item label="姓名" prop="name">
                <!-- <el-input v-model="AddReferralform.name"></el-input> -->
                <el-autocomplete
                  v-model="AddReferralform.name"
                  :fetch-suggestions="getPatientListdata"
                  placeholder="请输入内容"
                  @select="handleSelect"
                ></el-autocomplete>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="性别">
                <el-radio v-model="AddReferralform.sex" label="1">男</el-radio>
                <el-radio v-model="AddReferralform.sex" label="0">女</el-radio>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="年龄" prop="age">
                <el-input v-model="AddReferralform.age"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="联系电话" prop="phone">
                <el-input v-model="AddReferralform.phone"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="病情程度">
                <el-select v-model="AddReferralform.degree" placeholder="请选择病情程度" style="width: 100%">
                  <el-option label="轻度" value="1"></el-option>
                  <el-option label="中度" value="2"></el-option>
                  <el-option label="重度" value="3"></el-option>
                  <el-option label="严重" value="4"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="身份证号" prop="identity">
                <el-input v-model="AddReferralform.identity"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="初步诊断" prop="diagnosis">
                <el-input v-model="AddReferralform.diagnosis"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="联系地址" prop="address">
                <el-input v-model="AddReferralform.address"></el-input>
              </el-form-item>
            </el-col>
            <!-- <el-col :span="24">
              <el-form-item label="患者介绍">
                <el-input type="textarea" :rows="2" placeholder="请输入内容" v-model="AddReferralform.introduction"> </el-input> -->
            <!-- <quill-editor v-model="AddReferralform.introduction" ref="myQuillEditor" :content="AddReferralform.introduction" :options="editorOption" @change="onEditorChange($event)" /> -->
            <!-- </el-form-item> -->
            <!-- </el-col> -->
          </el-row>
          <!--<tinymce-editor ref="editor" v-model="value"></tinymce-editor>-->
        </el-form>
        <el-form :model="referralform" ref="referralform" :rules="rules">
          <el-form-item label="是否加急" :label-width="formLabelWidth">
            <el-switch :active-value="1" :inactive-value="0" v-model="referralform.severity" active-color="#13ce66">
            </el-switch>
          </el-form-item>
          <el-form-item label="转诊原因" :label-width="formLabelWidth" prop="reason">
                    <el-select style="width: 100%;" v-model="referralform.reason" placeholder="请选择转诊原因">
                    <el-option label="病情疑难复杂不能明确诊断，需要进一步诊治" value="病情疑难复杂不能明确诊断，需要进一步诊治"></el-option>
                    <el-option label="限于医疗机构的诊疗水平、技术能力或者设备条件等，不具备诊治能力" value="限于医疗机构的诊疗水平、技术能力或者设备条件等，不具备诊治能力"></el-option>
                    <el-option label="不具备相应的医疗技术临床应用资质或者手术资质" value="不具备相应的医疗技术临床应用资质或者手术资质"></el-option>
                    <el-option label="诊治疾病超出医疗机构执业登记的诊疗科目" value="诊治疾病超出医疗机构执业登记的诊疗科目"></el-option>
                    <el-option label="传染病按照有关规定需转入指定医疗机构治疗" value="传染病按照有关规定需转入指定医疗机构治疗"></el-option>
                    <el-option label="卫生健康行政部门规定的其他情形" value="卫生健康行政部门规定的其他情形"></el-option>
                    </el-select>
                </el-form-item>   
          <el-form-item label="备注" :label-width="formLabelWidth">
            <el-input type="textarea" :rows="2" placeholder="请输入内容" v-model="referralform.introduction"
              style="margin-top: 15px">
            </el-input>
          </el-form-item>
          <el-form-item label="" :label-width="formLabelWidth">
            <el-radio-group v-model="referralType" @change="selectReferralType">
              <el-radio :label="1">内部</el-radio>
              <el-radio :label="2">合作医院</el-radio> </el-radio-group>
            <!-- <el-checkbox style="margin-left: 15px" v-model="referralform.green" @change="checkboxChange">直接转诊到该医院的绿色通道</el-checkbox> -->
          </el-form-item>
          <el-form-item label="接诊医院" :label-width="formLabelWidth" prop="hospitalId"
            v-if="referralType == 2 && !referralform.green">
            <el-select v-model="referralform.hospitalId" placeholder="请选择接诊医院" @change="selectHospital">
              <el-option v-for="item in HospitalData" :label="item.name" :value="item.id" :key="item.index">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="接诊部门" prop="departmentId" :label-width="formLabelWidth" v-if="!referralform.green">
            <el-cascader ref="myCascader" :options="DepartmentData" :props="{
              expandTrigger: 'hover',
              emitPath: false,
              multiple: false,
              checkStrictly: true,
              value: 'id',
              label: 'name',
              children: 'childrens',
            }" v-model="referralform.departmentId" @change="selectDepartment" :disabled="disdepartment">
            </el-cascader>
          </el-form-item>
          <!--<el-form-item label="接诊部门" :label-width="formLabelWidth">
                    <el-select v-model="referralform.departmentId" placeholder="请选择接诊医院" >
                        <el-option v-for="item in DepartmentData"
                                   :label="item.name"
                                   :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>-->
          <el-form-item label="接诊医生" :label-width="formLabelWidth" prop="doctorId" v-if="!referralform.green">
            <el-select v-model="referralform.doctorId" placeholder="请选择接诊医生">
              <el-option v-for="item in DoctorData" :label="item.userName" :value="item.id" :key="item.index">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="allSaveform()">保存</el-button>
            <el-button @click="allDialog()">取消</el-button>
          </el-form-item>
        </el-form>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { Referral } from "../../components/Referral/Referral";
import { Organization } from "../../components/User/Organization";
import { UserInfo } from "../../components/User/UserInfo";
import ftCom from "../../components/footer";
import nhCom from "../../components/navheader";
import hdImg from "../../components/headerImg";
import { patientMain } from "../../components/patienTmanagement/patienTmanagement";
export default {
  name: "Referral",
  inject: ["reload"],
  components: {
    ftCom,
    nhCom,
    hdImg,
  },
  //注册局部过滤器
  filters: {
    timefilters(val) {
      if (val == null || val == "") {
        return "暂无时间";
      } else {
        let d = new Date(val); //val 为表格内取到的后台时间
        let month = d.getMonth() + 1 < 10 ? "0" + (d.getMonth() + 1) : d.getMonth() + 1;
        let day = d.getDate() < 10 ? "0" + d.getDate() : d.getDate();
        let hours = d.getHours() < 10 ? "0" + d.getHours() : d.getHours();
        let min = d.getMinutes() < 10 ? "0" + d.getMinutes() : d.getMinutes();
        let sec = d.getSeconds() < 10 ? "0" + d.getSeconds() : d.getSeconds();
        let times = d.getFullYear() + "-" + month + "-" + day + " " + hours + ":" + min + ":" + sec;
        return times;
      }
    },
  },
  data() {
    var patient = new patientMain(this.TokenClient, this.Services.Authorization);
    var referral = new Referral(this.TokenClient, this.Services.Referral);
    var organization = new Organization(this.TokenClient, this.Services.Authorization);
    var user = new UserInfo(this.TokenClient, this.Services.Authorization);
    return {
      Referral: referral,
      Organization: organization,
      patientDomain: patient,
      User: user,
      searchForm: {
        statrTime: "",
        endTime: "",
        keyWord: "",
        datepicker: "",
      },
      pickerOptions0: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
      },
      tabList: [
        {
          labelName: "全部转诊",
          state: "99",
        },
        {
          labelName: "待转诊",
          state: "-1",
        },
        {
          labelName: "待处理",
          state: "0",
        },
        {
          labelName: "处理中",
          state: "10",
        },
        {
          labelName: "已完成",
          state: "11",
        },
      ],
      tableWidth: "300",
      PageIndex: 1,
      PageTotal: 1,
      PageSize: 15,
      DataTotal: 0,
      recentList: [],
      state: "99",
      num: 0,
      datepicker: "",
      refeDetail: {},
      refeDetailDiv: false,
      referraltableData: [],
      HospitalData: [],
      DepartmentData: [],
      HospitalDepartmentId: [],
      DoctorData: [],
      referraldialog: false,
      AddReferralDrawer: false,
      allDrawer: false,
      ReferralDrawerTitle: "",
      size: "100%",
      referralform: {
        id: 0,
        severity: false,
        reason: "",
        content: "",
        hospitalId: "",
        green: false,
        departmentId: "",
        doctorId: "",
      },
      departmentName: "",
      disdepartment: false,
      AddReferralform: {
        name: "",
        sex: "1",
        age: "",
        phone: "",
        degree: "",
        identity: "",
        diagnosis: "",
        introduction: "",
        address: ""
      },
      editorOption: {
        placeholder: "编辑文章内容",
      },
      formLabelWidth: "120px",
      timelines: [],
      rules: {
        name: [{ required: true, message: "请输入姓名", trigger: "blur" }],
        sex: [{ required: true, message: "请选择性别", trigger: "blur" }],
        age: [
          {
            pattern: /^(\d|[1-9]\d|1[0-1]\d|120)$/,
            message: "请输入不大于120的整数",
            trigger: "blur",
          },
        ],
        phone: [
          { required: true, message: "请输入联系电话", trigger: "blur" },
          {
            pattern: /^((0\d{2,3}-\d{7,8})|(1[3456789]\d{9}))$/,
            message: "请输入合法手机号/电话号",
            trigger: "blur",
          },
        ],
        identity: [
          {
            pattern: /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/,
            message: "请输入正确格式的身份证号码",
            trigger: "blur",
          },
        ],
        degree: [{ required: true, message: "请选择病情程度", trigger: "blur" }],
        diagnosis: [{ required: true, message: "请输入初步诊断", trigger: "blur" }],
        reason: [{ required: true, message: "请选择转诊原因", trigger: "blur" }],
        hospitalId: [{ required: true, message: "请选择医院", trigger: "blur" }],
        departmentId: [{ required: true, message: "请选择部门", trigger: "blur" }],
        doctorId: [{ required: true, message: "请选择医生", trigger: "blur" }],
      },
      referralType: 1,
      userInfo: this.$store.state.localData.userInfo,
      orgData: {},
      diseasOptions: [],
      clickSearch: false
    };
  },
  mounted() {
    var _this = this;
    _this.FindAllReferral();
    _this.getRecent();
  },
  methods: {
    search() {
      var _this = this;
      _this.PageIndex = 1;
      _this.referraltableData = [];
      switch (_this.state) {
        case "99":
          _this.FindAllReferral();
          break;
        default:
          _this.getReferral();
          break;
      }
    },
    getReferral() {
      var _this = this;
      var item = _this.searchForm;
      if (_this.searchForm.datepicker) {
        item.startTime = _this.searchForm.datepicker[0] + " 00:00:00";
        item.endTime = _this.searchForm.datepicker[1] + " 23:59:59";
      } else {
        item.startTime = "";
        item.endTime = "";
      }
      _this.Referral.getReferral(
        _this.state,
        _this.PageIndex,
        item.startTime,
        item.endTime,
        item.keyWord,
        function (data) {
          _this.referraltableData = data.data.results;
          _this.PageIndex = data.data.pageIndex;
          _this.PageSize = data.data.pageSize;
          _this.PageTotal = data.data.pageCount;
          _this.DataTotal = data.data.dataTotal;
        },
        function (error) {
          console.log(error);
        }
      );
    },
    handleSelect(e) {
      this.AddReferralform = {
        name: e.name,
        sex: e.sex == true ? '1' : '0',
        age: e.age,
        phone: e.phone,
        degree: "",
        identity: e.idNo,
        diagnosis: "",
        introduction: "",
        address: e.address,
      }
    },
    //患者列表
    getPatientListdata(keyWord,cb) {
      var _this = this;
      _this.diseasOptions = [];
      _this.patientDomain.getPatientList(1,0,_this.userInfo.id,'','',keyWord,1,
        function (data) {
          console.log(data.data.results);
          _this.diseasOptions = data.data.results;
          let newSrt = data.data.results;
          if(newSrt){
            newSrt.map(item=>{
              item.value = item.name;
              return item;
            })
          }
          cb(newSrt);
        },
        function (error) {
          console.log(error);
        }
      );
    },
    
    ChangePage(pageIndex) {
      var _this = this;
      _this.PageIndex = pageIndex;
      _this.referraltableData = [];
      switch (_this.state) {
        case "99":
          _this.FindAllReferral();
          break;
        default:
          _this.getReferral();
          break;
      }
    },
    //角色类型状态
    ReferralSex(row) {
      return row.sex == 1 ? "女" : "男";
    },
    //状态 对应状态码
    ReferralDegree(row) {
      if (row.degree == "1") {
        return "轻度";
      } else if (row.degree == "2") {
        return "中度";
      } else if (row.degree == "3") {
        return "重度";
      } else if (row.degree == "4") {
        return "严重";
      }
    },
    ReferralState(row) {
      if (row.state == "0") {
        return "待处理";
      } else if (row.state == "10") {
        return "处理中";
      } else if (row.state == "-1") {
        return "待转诊";
      } else if (row.state == "11") {
        return "已完成";
      }
    },
    FindAllReferral() {
      var _this = this;
      var item = _this.searchForm;
      if (_this.searchForm.datepicker) {
        item.startTime = _this.searchForm.datepicker[0] + " 00:00:00";
        item.endTime = _this.searchForm.datepicker[1] + " 23:59:59";
      } else {
        item.startTime = "";
        item.endTime = "";
      }
      _this.Referral.AllReferral(
        _this.PageIndex,
        item.startTime,
        item.endTime,
        item.keyWord,
        function (data) {
          _this.referraltableData = data.data.results;
          _this.PageIndex = data.data.pageIndex;
          _this.PageSize = data.data.pageSize;
          _this.PageTotal = data.data.pageCount;
          _this.DataTotal = data.data.dataTotal;
        },
        function (error) {
          console.log(error);
        }
      );
    },
    setWidth() {
      switch (this.state) {
        case "99":
          this.tableWidth = "300";
          break;
        case "0":
          this.tableWidth = "160";
          break;
        case "11":
          this.tableWidth = "160";
          break;
        default:
          this.tableWidth = "300";
          break;
      }
    },
    handleClick(tab) {
      var _this = this;
      _this.setWidth();
      _this.PageIndex = 1;
      _this.referraltableData = [];
      switch (tab.name) {
        case "99":
          _this.FindAllReferral();
          break;
        default:
          _this.getReferral();
          break;
      }
    },
    FindWaitReferral() {
      var _this = this;
      var item = _this.searchForm;
      if (_this.searchForm.datepicker != "") {
        item.startTime = _this.searchForm.datepicker[0] + " 00:00:00";
        item.endTime = _this.searchForm.datepicker[1] + " 23:59:59";
      }
      _this.Referral.WaitReferral(
        _this.PageIndex,
        item.startTime,
        item.endTime,
        item.keyWord,
        function (data) {
          _this.referraltableData = data.data.results;
          _this.PageIndex = data.data.pageIndex;
          _this.PageSize = data.data.pageSize;
          _this.PageTotal = data.data.pageCount;
          _this.DataTotal = data.data.dataTotal;
        },
        function (error) {
          console.log(error);
        }
      );
    },
    FindWaitHandle() {
      var _this = this;
      var item = _this.searchForm;
      if (_this.searchForm.datepicker != "") {
        item.startTime = _this.searchForm.datepicker[0] + " 00:00:00";
        item.endTime = _this.searchForm.datepicker[1] + " 23:59:59";
      }
      _this.Referral.WaitHandle(
        _this.PageIndex,
        item.startTime,
        item.endTime,
        item.keyWord,
        function (data) {
          _this.referraltableData = data.data.results;
          _this.PageIndex = data.data.pageIndex;
          _this.PageSize = data.data.pageSize;
          _this.PageTotal = data.data.pageCount;
          _this.DataTotal = data.data.dataTotal;
        },
        function (error) {
          console.log(error);
        }
      );
    },
    FindHandling() {
      var _this = this;
      var item = _this.searchForm;
      if (_this.searchForm.datepicker != "") {
        item.startTime = _this.searchForm.datepicker[0] + " 00:00:00";
        item.endTime = _this.searchForm.datepicker[1] + " 23:59:59";
      }
      _this.Referral.Handling(
        _this.PageIndex,
        item.startTime,
        item.endTime,
        item.keyWord,
        function (data) {
          _this.referraltableData = data.data.results;
          _this.PageIndex = data.data.pageIndex;
          _this.PageSize = data.data.pageSize;
          _this.PageTotal = data.data.pageCount;
          _this.DataTotal = data.data.dataTotal;
        },
        function (error) {
          console.log(error);
        }
      );
    },
    FindFinish() {
      var _this = this;
      var item = _this.searchForm;
      if (_this.searchForm.datepicker != "") {
        item.startTime = _this.searchForm.datepicker[0] + " 00:00:00";
        item.endTime = _this.searchForm.datepicker[1] + " 23:59:59";
      }
      _this.Referral.Finish(
        _this.PageIndex,
        item.startTime,
        item.endTime,
        item.keyWord,
        function (data) {
          _this.referraltableData = data.data.results;
          _this.PageIndex = data.data.pageIndex;
          _this.PageSize = data.data.pageSize;
          _this.PageTotal = data.data.pageCount;
          _this.DataTotal = data.data.dataTotal;
        },
        function (error) {
          console.log(error);
        }
      );
    },
    selectHospital() {
      var _this = this;
      const str = _this.referralform.hospitalId ? this.referralform.hospitalId : this.userInfo.organizationId
      _this.Organization.Department(
        str,
        function (data) {
          _this.DepartmentData = data.data;
          _this.referralform.departmentId = "";
          _this.referralform.doctorId = "";
        },
        function (err) {
          console.log(err);
        }
      );
    },
    selectReferralType() {
      var _this = this;
      this.referralform.departmentId = "";
      this.referralform.doctorId = "";
      this.referralform.green = false;
      this.HospitalData = [];
      this.DepartmentData = [];
      this.DoctorData = [];
      if (this.referralType == 1) {
        this.referralform.hospitalId = this.userInfo.organizationId;
        this.selectHospital();
        this.getOrgInfo();
      } else {
        this.referralform.hospitalId = '';
        _this.Organization.getAllHospital(
          this.userInfo.organizationId,
          function (data) {
            _this.HospitalData = data.data;
          },
          function (err) {
            console.log(err);
          }
        );
      }
    },
    checkboxChange(e) {
      if (e) {
        this.referralType = 0;
      } else {
        this.referralType = 1;
      }
    },
    getOrgInfo() {
      console.log("***");
      var _this = this;
      _this.Organization.Detial(
        this.userInfo.organizationId,
        function (data) {
          _this.orgData = data.data;
        },
        function (err) {
          console.log(err);
        }
      );
    },
    selectGreen(data) {
      var _this = this;
      _this.referralform.green = data;
      _this.disdepartment = data;
      if (_this.referralform.green) {
        _this.referralform.departmentId = 29;
        _this.disdepartment = true;
      } else {
        _this.referralform.departmentId = "";
        _this.disdepartment = false;
      }
    },
    selectDepartment(data) {
      var _this = this;
      _this.departmentName = this.$refs.myCascader.getCheckedNodes()[0].label;
      const str = _this.referralform.hospitalId ? this.referralform.hospitalId : this.userInfo.organizationId
      _this.User.RefeAllUser(
        str,
        data,
        function (data) {
          _this.DoctorData = data.data;
          _this.referralform.doctorId = "";
        },
        function (err) {
          console.log(err);
        }
      );
    },
    ReferralBtn(item) {
      var _this = this;
      _this.referraldialog = true;
      _this.referralform.severity = false;
      _this.referralform.referralId = item.referralId;
      _this.referralform.reason = "";
      _this.referralform.content = "";
      _this.referralform.hospitalId = "";
      _this.referralform.green = false;
      _this.referralform.departmentId = "";
      _this.referralform.doctorId = "";
      // var thisOrganization = {
      //     id: _this.User.UserInfo().organizationId,
      //     name: _this.User.UserInfo().organizationName
      // }
      _this.selectReferralType();
    },
    // InfoReferralBtn(data) {
    //   var _this = this;
    //   _this.referralform.id = data.referralId;
    //   _this.referralform.urgent = false;
    //   _this.referralform.reason = "";
    //   _this.referralform.content = "";
    //   _this.referralform.hospitalId = "";
    //   _this.referralform.green = false;
    //   _this.referralform.departmentId = "";
    //   _this.referralform.doctorId = "";
    //   _this.referraldialog = true;
    //   var thisOrganization = {
    //     id: _this.User.UserInfo().organizationId,
    //     name: _this.User.UserInfo().organizationName,
    //   };
    //   _this.Organization.RefeHospital(
    //     function (data) {
    //       data.data.push(thisOrganization);
    //       _this.HospitalData = data.data;
    //     },
    //     function (err) {
    //       console.log(err);
    //       _this.HospitalData.push(thisOrganization);
    //     }
    //   );
    // },
    SaveUpload(formName) {
      var _this = this;
      var item = _this.referralform;
      var Hospital = _this.HospitalData.find(function (x) {
        return x.id === item.hospitalId;
      });
      var Doctor = {
        id: "",
        phone: "",
        userName: "",
      };
      if (this.referralType == 1) {
        Hospital = {
          phone: this.orgData.phone,
          name: this.orgData.name,
        };
      }
      if (_this.DoctorData.length > 0) {
        Doctor = _this.DoctorData.find(function (x) {
          return x.id === item.doctorId;
        });
      }
      this.$refs[formName].validate(valid => {
        if (valid) {
          _this.Referral.Upload(
            item.referralId,
            item.severity ? 1 : 0,
            item.reason,
            item.content,
            item.doctorId,
            item.hospitalId,
            Hospital.phone,
            Hospital.name,
            _this.departmentName,
            Doctor.phone,
            Doctor.userName,
            0,
            function (data) {
              _this.referraldialog = false;
              _this.allDrawer = false;
              _this.pageIndex = 1;
              _this.referralform.severity = false;
              _this.referralform.referralId = item.referralId;
              _this.referralform.reason = "";
              _this.referralform.content = "";
              _this.referralform.hospitalId = "";
              _this.referralform.green = false;
              _this.referralform.departmentId = "";
              _this.referralform.doctorId = "";
              _this.$message.success("新增成功");
              switch (_this.state) {
                case "99":
                  _this.FindAllReferral();
                  break;
                default:
                  _this.getReferral();
                  break;
              };
            },
            function (err) {
              _this.$message.error("新增失败");
              console.log(err);
            }
          );
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    AddReferralBtn() {
      this.allDrawer = true;
      // this.$refs.myQuillEditor.quill.enable(true);
      // this.ReferralDrawerTitle = "新增";
      this.AddReferralform.id = null;
      this.AddReferralform.name = "";
      this.AddReferralform.sex = "1";
      this.AddReferralform.age = "";
      this.AddReferralform.phone = "";
      this.AddReferralform.degree = "";
      this.AddReferralform.identity = "";
      this.AddReferralform.diagnosis = "";
      this.AddReferralform.introduction = "";
      this.selectReferralType();
    },

    EditReferralBtn(item) {
      this.AddReferralDrawer = true;
      this.ReferralDrawerTitle = "编辑";
      this.AddReferralform = item;
      this.AddReferralform.sex = item.sex.toString();
    },
    InfoEditReferralBtn(data) {
      this.AddReferralDrawer = true;
      this.ReferralDrawerTitle = "编辑";
      var editItem = data;
      this.AddReferralform.id = editItem.referralId;
      this.AddReferralform.name = editItem.name;
      this.AddReferralform.sex = parseInt(editItem.sex);
      this.AddReferralform.age = editItem.age;
      this.AddReferralform.tel = editItem.phone;
      this.AddReferralform.degree = editItem.degree;
      this.AddReferralform.idcard = editItem.identity;
      this.AddReferralform.diagnosis = editItem.diagnosis;
      this.AddReferralform.introduction = editItem.introduction;
    },
    //新增、编辑保存事件
    SaveReferralform(formName) {
      var _this = this;
      var item = _this.AddReferralform;
      _this.$refs[formName].validate(valid => {
        if (valid) {
          if (item.referralId) {
            _this.Referral.EditReferral(
              item.referralId,
              item.name,
              parseInt(item.sex),
              item.age,
              item.phone,
              item.identity,
              item.degree,
              item.diagnosis,
              item.introduction,
              function (data) {
                _this.AddReferralDrawer = false;
                _this.PageIndex = 1;
                _this.$message.success("编辑成功");
                switch (_this.state) {
                  case "99":
                    _this.FindAllReferral();
                    break;
                  default:
                    _this.getReferral();
                    break;
                }
              },
              function (err) {
                _this.$message.error("编辑失败");
                console.log(err);
              }
            );
          } else {
            _this.Referral.AddReferral(
              item.name,
              parseInt(item.sex),
              item.age,
              item.phone,
              item.identity,
              item.degree,
              item.diagnosis,
              _this.referralform.introduction,
              0,
              item.address,
              function (data) {
                _this.AddReferralDrawer = false;
                
                _this.PageIndex = 1;
                switch (_this.state) {
                  case "99":
                    _this.FindAllReferral();
                    break;
                  default:
                    _this.getReferral();
                    break;
                }
              },
              function (err) {
                console.log(err);
              }
            );
          }
        } else {
          return false;
        }
      });
    },
    cancelDialog(formName) {
      var _this = this;
      _this.AddReferralDrawer = false;
      _this.referraldialog = false;
      this.$refs[formName].resetFields();
    },
    allDialog() {
      var _this = this;
      _this.allDrawer = false;
      this.$refs.AddReferralform.resetFields();
      this.$refs.referralform.resetFields();
      _this.referralform.severity = false;
      _this.referralform.referralId = _this.referralId;
      _this.referralform.reason = "";
      _this.referralform.content = "";
      _this.referralform.hospitalId = "";
      _this.referralform.green = false;
      _this.referralform.departmentId = "";
      _this.referralform.doctorId = "";
    },
    //新增转诊
    allSaveform() {
      var _this = this;
      var item = _this.AddReferralform;
      _this.$refs.AddReferralform.validate(valid => {
        if (valid) {
          _this.$refs.referralform.validate(valids => {
            if(valids){
              _this.Referral.AddReferral(
                item.name,
                parseInt(item.sex),
                item.age,
                item.phone,
                item.identity,
                item.degree,
                item.diagnosis,
                _this.referralform.introduction,
                0,
                item.address,
                function (data) {
                  _this.referralform.referralId = data.data.referralId;
                  _this.addPatientForm();
                  _this.SaveUpload('referralform');
                },
                function (err) {
                  console.log(err);
                })
            }
            
          });
        }
      });
    },
    //新增患者
    addPatientForm() {
      const _this = this;
      const newStr = this.AddReferralform;
      const param = {
        name: newStr?.name,
        age: newStr?.age,
        phone: newStr?.phone,
        idNo: newStr?.idNo,
        address: newStr?.address,
        sex: newStr.sex == '1' ? true : false,
        diseaseName: '',
        PatientType: 1,
        userId: _this.userInfo.id
      }
      _this.patientDomain.patientAdd(
        param,
        function (data) {
          
        },
        function (error) {
          console.log(error);
        }
      );
    },
    //删除此条转诊记录
    RemovePost(item) {
      var _this = this;
      this.$confirm("确定删除此条转诊记录吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          _this.Referral.Remove(
            item.referralId,
            function (data) {
              /*_this.FindAllReferral();*/
              _this.pageIndex = 1;
              switch (_this.state) {
                case "99":
                  _this.FindAllReferral();
                  break;
                default:
                  _this.getReferral();
                  break;
              }
              _this.$message({
                type: "success",
                message: "删除成功!",
              });
            },
            function (err) {
              console.log(err);
            }
          );
        })
        .catch(() => {
          _this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    InfoRemovePost(data) {
      var _this = this;
      this.$confirm("确定删除此条转诊记录吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          _this.Referral.Remove(
            data,
            function (data) {
              _this.$message({
                type: "success",
                message: "删除成功!",
              });
              _this.reload();
            },
            function (err) {
              console.log(err);
            }
          );
        })
        .catch(() => {
          _this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    //撤回转诊记录
    RemoveUndo(item) {
      var _this = this;
      this.$confirm("确定撤回此条转诊记录吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          _this.Referral.Undo(
            item.referralId,
            function (data) {
              _this.pageIndex = 1;
              switch (_this.state) {
                case "99":
                  _this.FindAllReferral();
                  break;
                default:
                  _this.getReferral();
                  break;
              }
              _this.$message({
                type: "success",
                message: "撤回成功!",
              });
            },
            function (err) {
              console.log(err);
            }
          );
        })
        .catch(() => {
          _this.$message({
            type: "info",
            message: "已取消撤回",
          });
        });
    },
    InfoRemoveUndo(data) {
      var _this = this;
      this.$confirm("确定撤回此条转诊记录吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          _this.Referral.Undo(
            data,
            function (data) {
              _this.$message({
                type: "success",
                message: "撤回成功!",
              });
              _this.reload();
            },
            function (err) {
              console.log(err);
            }
          );
        })
        .catch(() => {
          _this.$message({
            type: "info",
            message: "已取消撤回",
          });
        });
    },
    //近期转诊
    getRecent() {
      var _this = this;
      _this.Referral.Recent(
        function (data) {
          _this.recentList = data.data;
        },
        function (error) {
          console.log(error);
        }
      );
    },
    //返回列表页
    goBack() {
      $(".center-content").show();
      $(".detail-box").hide();
    },
    //患者详情
    toDetail(row, type) {
      this.$router.push({
        name: "referralDtail",
        query: {
          referralId: row.referralId,
          patientId: row.patientId,
          type: type
        }
      });
      return;
      if (column.label != "操作") {
        this.refeDetailDiv = true;
        $(".center-content").hide();
        $(".detail-box").show();
        var _this = this;
        _this.Referral.refeDetail(
          row.referralId,
          function (data) {
            _this.refeDetail = data.data;
          },
          function (error) {
            console.log(error);
          }
        );
        _this.Referral.TimeLine(
          row.referralId,
          function (data) {
            _this.timelines = data.data;
          },
          function (error) {
            console.log(error);
          }
        );
      }
    },
    onEditorChange({ editor, html, text }) {
      this.content = html;
    },
  },
  computed: {
    editor() {
      return this.$refs.myQuillEditor.quill;
    },
  },
  /* watch: {
         referralform:{
             departmentId(){
                 if (this.$refs.cascader) {
                     this.$refs.cascader.dropDownVisible = false
                 }
             },
             deep:true,
         }
     },*/
};
</script>

<style scoped>
.detail-tab {
  height: 70px;
  line-height: 70px;
  background: #ffffff;
  margin-bottom: 30px;
}

.detail-tab>li {
  width: 50%;
  display: inline-block;
  font-size: 24px;
  font-weight: bold;
  padding-left: 15px;
  box-sizing: border-box;
  cursor: pointer;
}

.detail-tab>li.active {
  background: #f8f8f8;
  box-shadow: 2px 0px 10px rgba(31, 183, 166, 0.19);
}

.detail-tab>li em {
  font-style: normal;
  color: #04a290;
}

.detail-tab>li .iconfont {
  font-size: 24px;
  color: #04a290;
  margin-right: 5px;
  font-weight: normal;
}



.detail-item {
  margin-bottom: 30px;
}

.detail-tit {
  padding-left: 20px;
  height: 48px;
  line-height: 48px;
  font-size: 16px;
  color: #ffffff;
  background: #1fb7a6;
}

.detail-info {
  padding: 10px 30px;
  background: #ffffff;
}

.detail-info ul li {
  padding: 5px 0;
  color: #333333;
  font-size: 15px;
}

.detail-info ul li span {
  display: inline-block;
  width: 100px;
  vertical-align: top;
}

.detail-info ul li i {
  display: inline-block;
  font-style: normal;
  width: calc(100% - 130px);
}

.detail-info p {}

.btn-grounp {
  text-align: center;
}

.btn-grounp .el-button {
  width: 90px;
}

@import "../../assets/css/footer.css";

.banner {
  width: 100%;
  height: 160px;
  background: url("../../assets/img/news_banner.jpg") center no-repeat;
}

.banner-wrap {
  position: relative;
  width: 1200px;
  height: 100%;
  margin: 0 auto;
}

.banner-text {
  float: left;
}

.banner-text h3 {
  padding-top: 35px;
  font-size: 26px;
  font-weight: normal;
  color: #ffffff;
}

.banner-text p {
  font-size: 14px;
  color: #ffffff;
  margin-top: 20px;
}

.banner-text p a {
  color: #ffffff;
  font-size: 14px;
  margin: 0 5px;
}

/**/
.personal-content {
  background: #f7f7f7;
}


.personal-left {
  float: left;
}

.center-content {
  box-sizing: border-box;
}


.iq-card-body {
  padding: 15px;
  border-radius: 15px;
  background: #ffffff;
}

.list-img {
  margin-right: 15px;
  width: 70px;
  height: 70px;
  border-radius: 50%;
  overflow: hidden;
  border: 1px solid #e5e5e5;
}

.list-img img {
  width: 100%;
  height: 100%;
}

.list-mes {
  padding: 10px 0;
  width: calc(100% - 90px);
}

.list-mes p,
.list-mes h3 {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.list-bottom {
  margin-top: 15px;
}

/*.table-top {
        margin-bottom: 15px;
    }*/

.iq-search-bar {
  display: inline-block;
  margin-right: 20px;
}

.iq-search-bar .searchbox {
  position: relative;
}

.iq-search-bar .searchbox .search-link {
  position: absolute;
  right: 15px;
  top: 2px;
  font-size: 16px;
}

.iq-search-bar .search-input {
  width: 100%;
  padding: 5px 40px 5px 15px;
  border-radius: 10px;
  border: none;
  background: #ceeede;
  line-height: 1.8;
  box-sizing: border-box;
}

.addreferral {
  width: 80%;
  margin: 0 auto;
}

.addreferral .el-form {
  width: 100%;
}

.no-data {
  height: 144px;
  text-align: center;
}

.no-data img {
  height: 100px;
}

.no-data p {
  color: #04a290;
}
</style>
<style>
.personal-center {
  display: none;
}

.el-select-dropdown__wrap,
.el-cascader-menu__wrap {
  margin-bottom: 0 !important;
}

.ql-snow .ql-picker-label::before {
  vertical-align: top;
}

.ql-container {
  height: 150px;
  overflow-y: auto;
}

.el-divider__text {
  background-color: #f7f7f7;
}

.el-date-editor .el-range-separator {
  width: 10%;
}

.el-dropdown-menu__item {
  cursor: auto;
}

.el-dropdown-menu__item:hover {
  background-color: rgba(0, 0, 0, 0) !important;
}

.el-table th,
.el-table tr {
  cursor: pointer;
}
</style>
