<template>
    <div>
        <div class="personal-content">
            <div class="center-content">
                <div class="iq-card-body" style="margin-top: 20px;">
                    <div class="table-top">
                        <div class="iq-search-bar">
                            <el-form :model="searchForm" :inline="true" class="searchbox">
                                <el-form-item>
                                    <el-date-picker v-model="searchForm.datepicker" type="daterange" range-separator="至"
                                        start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd"
                                        :picker-options="pickerOptions0" size="small" style="margin-right: 10px">
                                    </el-date-picker>
                                </el-form-item>
                                <el-form-item>
                                    <el-input v-model="searchForm.keyWord" type="text" size="small"
                                        placeholder="请输入姓名/手机号搜索" @keyup.enter.native="SearchInputKeydown" clearable>
                                    </el-input>
                                </el-form-item>
                                <el-form-item>
                                    <el-button type="primary" size="small" @click="search()">搜索</el-button>
                                </el-form-item>
                            </el-form>
                        </div>
                    </div>
                    <el-tabs v-model="state" @tab-click="handleClick">
                        <el-tab-pane :label="item.labelName" :name="item.state" v-for="(item, index) in tabList"
                            :key="index">
                            <el-table :data="treatedtableData" stripe style="width: 100%">
                                <el-table-column label="姓名" width="100">
                                    <template slot-scope="scope">
                                        <p>{{ scope.row.name }}<span style="color: red;"
                                                v-if="scope.row.severity == 1">(急)</span></p>
                                    </template>
                                </el-table-column>
                                <el-table-column prop="diagnosis" label="初步诊断">
                                </el-table-column>
                                <el-table-column label="转诊医生" width="250">
                                    <template slot-scope="scope">
                                        <p>{{ scope.row.referralName }}<span v-if="scope.row.referralPhone">【{{
                                            scope.row.referralPhone
                                                }}】</span></p>
                                    </template>
                                </el-table-column>
                                <el-table-column prop="referralHospitalName" label="转诊医院">
                                </el-table-column>
                                <el-table-column prop="referralDermant" label="转诊部门">
                                </el-table-column>
                                <el-table-column prop="receptionTime" label="接诊时间" width="150px">
                                    <template slot-scope="scope">
                                        <p>{{ scope.row.receptionTime | timefilters }}</p>
                                    </template>
                                </el-table-column>
                                <el-table-column prop="state" label="状态" :formatter="TreatedState">
                                </el-table-column>
                                <el-table-column fixed="right" label="操作" :width="tableWidth">
                                    <template slot-scope="scope">
                                        <el-button v-if="scope.row.state == '0'" type="warning" size="mini"
                                            @click="ReceiveReferral(scope.$index)" plain>接收</el-button>
                                        <el-button type="warning" size="mini" @click="toDetail(scope.row, 'reception')"
                                            plain>详情</el-button>
                                        <el-button type="primary" v-if="scope.row.state != '11'" size="mini"
                                            @click="DepartmentBtn(scope.$index)" plain>转部门</el-button>
                                        <el-button v-if="scope.row.state != '11'" type="primary" size="mini"
                                            @click="ReferralBtn(scope.$index)" plain>转院</el-button>
                                        <el-button v-if="scope.row.state == '10'" type="success" size="mini"
                                            @click="ProcesBtn(scope.$index)" plain>完成</el-button>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </el-tab-pane>
                    </el-tabs>
                    <el-pagination @current-change="ChangePage" :page-size="PageSize" :current-page="PageIndex"
                        :total="DataTotal" layout="total, prev, pager, next, jumper"></el-pagination>
                </div>
            </div>
            <!--    详情    -->
            <div v-show="false" class="detail-box">
                <ul class="detail-tab">
                    <li @click="num = 0" :class="{ active: num == 0 }"><i class="iconfont">&#xe643;</i>患者<em>详情</em>
                    </li>
                    <li @click="num = 1" :class="{ active: num == 1 }"><i class="iconfont">&#xe62b;</i>时间<em>轴</em></li>
                </ul>
                <div v-show="num == 0">
                    <div class="detail-item">
                        <div class="detail-tit">基本信息</div>
                        <div class="detail-info">
                            <el-row>
                                <el-col :span="12">
                                    <ul>
                                        <li>
                                            <span>姓名：</span>
                                            <i>{{ refeDetail.name }}</i>
                                        </li>
                                        <li>
                                            <span>年龄：</span>
                                            <i>{{ refeDetail.age }}</i>
                                        </li>
                                        <li>
                                            <span>身份证号：</span>
                                            <i>{{ refeDetail.identity }}</i>
                                        </li>
                                    </ul>
                                </el-col>
                                <el-col :span="12">
                                    <ul>
                                        <li>
                                            <span>性别：</span>
                                            <i v-html="refeDetail.sex == 1 ? '男' : '女'"></i>
                                        </li>
                                        <li>
                                            <span>电话：</span>
                                            <i>{{ refeDetail.phone }}</i>
                                        </li>
                                        <li>
                                            <span>初步诊断：</span>
                                            <i>{{ refeDetail.diagnosis }}</i>
                                        </li>
                                    </ul>
                                </el-col>
                                <el-col :span="24">
                                    <ul>
                                        <li>
                                            <span>备注：</span>
                                            <i v-if="refeDetail.introduction == ''">
                                                暂无备注
                                            </i>
                                            <i v-else v-html="refeDetail.introduction"></i>
                                        </li>
                                    </ul>
                                </el-col>
                            </el-row>
                        </div>
                    </div>
                </div>
                <div v-show="num == 1" class="block">
                    <el-timeline :reverse="true">
                        <el-timeline-item v-for="(activity, index) in timelines" :key="index" :icon="activity.icon"
                            :type="activity.type" color="#04a290" :size="activity.size">
                            {{ activity.receptionTime | timefilters }}
                            <el-card>
                                <h4>{{ activity.remark }}</h4>
                                <p>{{ activity.handHospitalName }} {{ activity.handDermantName }}</p>
                                <p>医生：{{ activity.handler }}</p>
                            </el-card>
                        </el-timeline-item>
                    </el-timeline>
                </div>
                <div class="btn-grounp">
                    <el-button @click="goBack">上一页</el-button>
                </div>
            </div>
        </div>
        <!-- <ft-com></ft-com> -->
        <!--    转部门    -->
        <el-dialog title="转部门" :visible.sync="departmentdialog">
            <el-form :model="departmentForm" ref="departmentForm" :rules="rules">
                <el-form-item label="是否加急" :label-width="formLabelWidth">
                    <el-switch :active-value="1" :inactive-value="0" v-model="departmentForm.urgent"
                        active-color="#13ce66">
                    </el-switch>
                </el-form-item>
                <el-form-item label="转诊原因" :label-width="formLabelWidth" prop="reason">
                    <el-select style="width: 100%;" v-model="departmentForm.reason" placeholder="请选择转诊原因">
                    <el-option label="病情疑难复杂不能明确诊断，需要进一步诊治" value="病情疑难复杂不能明确诊断，需要进一步诊治"></el-option>
                    <el-option label="限于医疗机构的诊疗水平、技术能力或者设备条件等，不具备诊治能力" value="限于医疗机构的诊疗水平、技术能力或者设备条件等，不具备诊治能力"></el-option>
                    <el-option label="不具备相应的医疗技术临床应用资质或者手术资质" value="不具备相应的医疗技术临床应用资质或者手术资质"></el-option>
                    <el-option label="诊治疾病超出医疗机构执业登记的诊疗科目" value="诊治疾病超出医疗机构执业登记的诊疗科目"></el-option>
                    <el-option label="传染病按照有关规定需转入指定医疗机构治疗" value="传染病按照有关规定需转入指定医疗机构治疗"></el-option>
                    <el-option label="卫生健康行政部门规定的其他情形" value="卫生健康行政部门规定的其他情形"></el-option>
                    </el-select>
                </el-form-item>   

                <el-form-item label="" :label-width="formLabelWidth">
                    <el-input type="textarea" :rows="2" placeholder="请输入内容" v-model="departmentForm.content"
                        style="margin-top: 15px;">
                    </el-input>
                </el-form-item>
                <el-form-item label="接诊部门" :label-width="formLabelWidth" prop="department">
                    <el-cascader ref="myCascader" :key="keyValue" :options="DepartmentData"
                        v-model="departmentForm.department"
                        :props="{ expandTrigger: 'hover', emitPath: false, multiple: false, checkStrictly: true, value: 'id', label: 'name', children: 'childrens' }"
                        @change="TransferDepartment"></el-cascader>
                </el-form-item>
                <el-form-item label="接诊医生" :label-width="formLabelWidth" prop="doctor">
                    <el-select v-model="departmentForm.doctor" placeholder="请选择接诊医生">
                        <el-option v-for="item in DoctorData" :label="item.userName" :value="item.id" :key="item.index">
                        </el-option>
                    </el-select>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="cancelDialog('departmentForm')">取 消</el-button>
                <el-button type="primary" @click="SavaDepartment('departmentForm')">确 定</el-button>
            </div>
        </el-dialog>
        <!--    转出弹框    -->
        <el-dialog title="转出至" :visible.sync="treateddialog">
            <el-form :model="treatedForm" ref="treatedForm" :rules="rules">
                <el-form-item label="是否加急" :label-width="formLabelWidth">
                    <el-switch :active-value="1" :inactive-value="0" v-model="treatedForm.urgent"
                        active-color="#13ce66">
                    </el-switch>
                </el-form-item>
                <el-form-item label="转诊原因" :label-width="formLabelWidth" prop="reason">
                    <el-select style="width: 100%;" v-model="treatedForm.reason" placeholder="请选择转诊原因">
                    <el-option label="病情疑难复杂不能明确诊断，需要进一步诊治" value="病情疑难复杂不能明确诊断，需要进一步诊治"></el-option>
                    <el-option label="限于医疗机构的诊疗水平、技术能力或者设备条件等，不具备诊治能力" value="限于医疗机构的诊疗水平、技术能力或者设备条件等，不具备诊治能力"></el-option>
                    <el-option label="不具备相应的医疗技术临床应用资质或者手术资质" value="不具备相应的医疗技术临床应用资质或者手术资质"></el-option>
                    <el-option label="诊治疾病超出医疗机构执业登记的诊疗科目" value="诊治疾病超出医疗机构执业登记的诊疗科目"></el-option>
                    <el-option label="传染病按照有关规定需转入指定医疗机构治疗" value="传染病按照有关规定需转入指定医疗机构治疗"></el-option>
                    <el-option label="卫生健康行政部门规定的其他情形" value="卫生健康行政部门规定的其他情形"></el-option>
                    </el-select>
                </el-form-item>   
                <el-form-item label="" :label-width="formLabelWidth">
                    <el-input type="textarea" :rows="2" placeholder="请输入内容" v-model="treatedForm.content"
                        style="margin-top: 15px;">
                    </el-input>
                </el-form-item>
                <el-form-item label="转出至：" :label-width="formLabelWidth" prop="radio">
                    平台合作医院
					 <!-- <el-input v-model="treatedForm.radio">平台合作医院</el-input>
                    <el-radio-group v-model="treatedForm.radio" @change="changeRadio">
                        <el-radio :label="0">平台合作医院</el-radio>
                        <el-radio :label="1">其他医院</el-radio>
                    </el-radio-group> -->

                </el-form-item>
                <el-form-item v-if="treatedForm.radio == 1" label="医院名称" :label-width="formLabelWidth"
                    prop="otherHospital">
                    <el-input v-model="treatedForm.otherHospital" placeholder="请输入医院名称"></el-input>
                </el-form-item>
                <el-form-item v-if="treatedForm.radio == 0" label="接诊医院" :label-width="formLabelWidth"
                    prop="hospitalId">
                    <el-select v-model="treatedForm.hospitalId" placeholder="请选择接诊医院" @change="OtherHospital">
                        <el-option v-for="item in HospitalData" :label="item.name" :value="item.id" :key="item.index">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item v-if="treatedForm.radio == 0" label="接诊部门" :label-width="formLabelWidth"
                    prop="departmentId">
                    <el-cascader ref="cascader" :options="DepartmentData" v-model="treatedForm.departmentId"
                        :props="{ expandTrigger: 'hover', emitPath: false, checkStrictly: true, value: 'id', label: 'name', children: 'childrens' }"
                        @change="OtherDepartment"></el-cascader>
                </el-form-item>
                <el-form-item v-if="treatedForm.radio == 0" label="接诊医生" :label-width="formLabelWidth" prop="doctorId">
                    <el-select v-model="treatedForm.doctorId" placeholder="请选择接诊医生">
                        <el-option v-for="item in DoctorData" :label="item.userName" :value="item.id" :key="item.index">
                        </el-option>
                    </el-select>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="cancelDialog('treatedForm')">取 消</el-button>
                <el-button type="primary" @click="SavaTransfer('treatedForm')">确 定</el-button>
            </div>
        </el-dialog>
        <!--    处理弹框    -->
        <el-dialog title="处理" :visible.sync="procesdialog">
            <el-form :model="procesForm" ref="procesForm" :rules="rules">
                <el-form-item label="患者处理结果：" :label-width="formLabelWidth" prop="content">
                    <el-input type="textarea" :rows="2" placeholder="请输入内容" v-model="procesForm.content"
                        style="margin-top: 15px;">
                    </el-input>
                </el-form-item>
                <el-form-item label="患者返回至：" :label-width="formLabelWidth" prop="radio">
                    原转诊医院
                    <!-- <el-radio-group v-model="procesForm.radio" @change="finishRadio">
                        <el-radio :label="1" :checked="procesForm.radio == '1'">原转诊医院</el-radio> -->
                        <!-- <el-radio  :label="2">平台合作医院</el-radio> -->
                    <!-- </el-radio-group> -->

                </el-form-item>
                <el-form-item v-if="procesForm.radio == 2" label="接诊医院" :label-width="formLabelWidth" prop="hospital">
                    <el-select v-model="procesForm.hospital" placeholder="请选择接诊医院" @change="selectHospital">
                        <el-option v-for="item in HospitalData" :label="item.name" :value="item.id" :key="item.index">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item v-if="procesForm.radio == 2" label="接诊部门" :label-width="formLabelWidth" prop="department">
                    <el-cascader :options="DepartmentData"
                        :props="{ expandTrigger: 'hover', emitPath: false, multiple: false, checkStrictly: true, value: 'id', label: 'name', children: 'childrens' }"
                        v-model="procesForm.department" @change="selectDepartment"></el-cascader>
                </el-form-item>
                <el-form-item v-if="procesForm.radio == 2" label="接诊医生" :label-width="formLabelWidth" prop="doctor">
                    <el-select v-model="procesForm.doctor" placeholder="请选择接诊医生">
                        <el-option v-for="item in DoctorData" :label="item.userName" :value="item.id" :key="item.index">
                        </el-option>
                    </el-select>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="cancelDialog('procesForm')">取 消</el-button>
                <el-button type="primary" @click="SaveProcesForm('procesForm')">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import intNavheader from "../../components/intNavheader";
import { Referral } from '../../components/Referral/Referral';
import { Treated } from '../../components/Referral/Treated';
import { Organization } from "../../components/User/Organization"
import { UserInfo } from '../../components/User/UserInfo';
import ftCom from '../../components/footer'
import nhCom from "../../components/navheader";
import hdImg from "../../components/headerImg";
import { patientMain } from "../../components/patienTmanagement/patienTmanagement";
export default {
    name: "Treated",
    inject: ["reload"],
    components: {
        ftCom,
        nhCom,
        hdImg,
        intNavheader
    },
    //注册局部过滤器
    filters: {
        timefilters(val) {
            if (val == null || val == "") {
                return "暂无时间";
            } else {
                let d = new Date(val);   //val 为表格内取到的后台时间
                let month =
                    d.getMonth() + 1 < 10 ? "0" + (d.getMonth() + 1) : d.getMonth() + 1;
                let day = d.getDate() < 10 ? "0" + d.getDate() : d.getDate();
                let hours = d.getHours() < 10 ? "0" + d.getHours() : d.getHours();
                let min = d.getMinutes() < 10 ? "0" + d.getMinutes() : d.getMinutes();
                let sec = d.getSeconds() < 10 ? "0" + d.getSeconds() : d.getSeconds();
                let times = d.getFullYear() + '-' + month + '-' + day + ' ' + hours + ':' + min + ':' + sec;
                return times;
            }
        }
    },
    data() {
        var patient = new patientMain(this.TokenClient, this.Services.Authorization);
        var referral = new Referral(this.TokenClient, this.Services.Referral);
        var treated = new Treated(this.TokenClient, this.Services.Referral);
        var organization = new Organization(this.TokenClient, this.Services.Authorization);
        var user = new UserInfo(this.TokenClient, this.Services.Authorization);
        return {
            Referral: referral,
            Treated: treated,
            Organization: organization,
            patientDomain: patient,
            User: user,
            searchForm: {
                statrTime: '',
                endTime: '',
                keyWord: '',
                datepicker: ''
            },
            pickerOptions0: {
                disabledDate(time) {
                    return time.getTime() > Date.now();
                }
            },
            tableWidth: "300",
            PageIndex: 1,
            PageTotal: 1,
            PageSize: 15,
            DataTotal: 0,
            recentList: [],
            state: '99',
            num: 0,
            datepicker: '',
            refeDetail: {},
            treatedtableData: [],
            HospitalData: [],
            DepartmentData: [],
            DoctorData: [],
            DepartmentId: '',
            keyValue: 0,
            treateddialog: false,
            departmentdialog: false,
            procesdialog: false,
            AddTreatedDrawer: false,
            size: '100%',
            departmentForm: {
                id: 0,
                urgent: 0,
                reason: '',
                content: '',
                hospitalId: '',
                department: '',
                doctor: '',
            },
            treatedForm: {
                id: 0,
                urgent: 0,
                reason: '',
                content: '',
                radio: '1',
                otherHospital: '',
                hospitalId: '',
                departmentId: '',
                doctorId: '',
            },
            treatedFormDepartment: '',
            procesForm: {
                id: 0,
                content: '',
                radio: '1',
                hospital: '',
                department: '',
                doctor: '',
            },
            departmentName: '',
            AddTreatedform: {
                name: '',
                sex: '',
                age: '',
                tel: '',
                degree: '',
                idcard: '',
                diagnosis: '',
                content: '',
            },
            editorOption: {
                placeholder: '编辑文章内容'
            },
            formLabelWidth: '120px',
            timelines: [],
            tabList: [
                {
                    labelName: "全部接诊",
                    state: '99'
                }, {
                    labelName: "待处理",
                    state: '0'
                }, {
                    labelName: "处理中",
                    state: '10'
                }, {
                    labelName: "已完成",
                    state: '11'
                },
            ],
            rules: {
                reason: [
                    { required: true, message: '请选择转诊原因', trigger: 'blur' }
                ],
                hospital: [
                    { required: true, message: '请选择医院', trigger: 'blur' }
                ],
                department: [
                    { required: true, message: '请选择部门', trigger: 'blur' }
                ],
                doctor: [
                    { required: true, message: '请选择医生', trigger: 'blur' }
                ],
                hospitalId: [
                    { required: true, message: '请选择医院', trigger: 'blur' }
                ],
                departmentId: [
                    { required: true, message: '请选择部门', trigger: 'blur' }
                ],
                doctorId: [
                    { required: true, message: '请选择医生', trigger: 'blur' }
                ],
                content: [
                    { required: true, message: '请输入内容', trigger: 'blur' }
                ],
                radio: [
                    { required: true, message: '选择转出至', trigger: 'blur' }
                ],
                otherHospital: [
                    { required: true, message: '请输入医院名称', trigger: 'blur' }
                ],
            },
            userInfo: this.$store.state.localData.userInfo,
            userReferralId: null,
            patientDiagnose: {},
            patientDetailAll: {},
            itemData: ''
        };
    },
    mounted() {
        var _this = this;
        _this.FindAllTreated();
        _this.getRecent();
    },
    methods: {
        ChangePage(pageIndex) {
            var _this = this
            _this.PageIndex = pageIndex;
            _this.treatedtableData = [];
            switch (_this.state) {
                case '99':
                    _this.FindAllTreated();
                    break;
                default:
                    _this.getTreated()
                    break;
            }
        },
        goBack() {
            $('.center-content').show();
            $('.detail-box').hide();
        },
        toDetail(row, type) {
            this.$router.push({
                name: "referralDtail",
                query: {
                    referralId: row.referralId,
                    patientId: row.patientId,
                    type: type
                }
            });
        },
        //近期接诊
        getRecent() {
            var _this = this;
            _this.Treated.RecentReception(
                function (data) {
                    _this.recentList = data.data;
                },
                function (error) {
                    console.log(error);
                });
        },
        //角色类型状态
        TreatedSex(row) {
            return row.sex == 1 ? '女' : '男'
        },
        //状态 对应状态码
        TreatedDegree(row) {
            if (row.degree == '1') {
                return '轻度'
            } else if (row.degree == '2') {
                return '中度';
            } else if (row.degree == '3') {
                return '重度';
            } else if (row.degree == '4') {
                return '严重';
            }
        },
        TreatedState(row) {
            if (row.state == '10') {
                return '处理中';
            } else if (row.state == '0') {
                return '待处理';
            } else if (row.state == '11') {
                return '已完成';
            }
        },
        FindAllTreated() {
            var _this = this;
            var item = _this.searchForm;
            if (_this.searchForm.datepicker) {
                item.startTime = _this.searchForm.datepicker[0] + ' 00:00:00';
                item.endTime = _this.searchForm.datepicker[1] + ' 23:59:59';
            } else {
                item.startTime = "";
                item.endTime = "";
            }
            _this.Treated.AllTreated(_this.PageIndex, item.startTime, item.endTime, item.keyWord,
                function (data) {
                    _this.treatedtableData = data.data.results;
                },
                function (error) {
                    console.log(error);
                });
        },
        getTreated() {
            var _this = this;
            var item = _this.searchForm;
            if (_this.searchForm.datepicker) {
                item.startTime = _this.searchForm.datepicker[0] + ' 00:00:00';
                item.endTime = _this.searchForm.datepicker[1] + ' 23:59:59';
            } else {
                item.startTime = "";
                item.endTime = "";
            }
            _this.Treated.getTreated(_this.state, _this.PageIndex, item.startTime, item.endTime, item.keyWord,
                function (data) {
                    _this.treatedtableData = data.data.results;
                    _this.PageIndex = data.data.pageIndex;
                    _this.PageSize = data.data.pageSize;
                    _this.PageTotal = data.data.pageCount;
                    _this.DataTotal = data.data.dataTotal;
                },
                function (error) {
                    console.log(error);
                });
        },
        FindWaitHandle() {
            var _this = this;
            var item = _this.searchForm;
            if (_this.searchForm.datepicker != '') {
                item.startTime = _this.searchForm.datepicker[0] + ' 00:00:00';
                item.endTime = _this.searchForm.datepicker[1] + ' 23:59:59';
            }
            _this.Treated.WaitHandle(_this.PageIndex, item.startTime, item.endTime, item.keyWord,
                function (data) {
                    _this.treatedtableData = data.data.results;
                    _this.PageIndex = data.data.pageIndex;
                    _this.PageSize = data.data.pageSize;
                    _this.PageTotal = data.data.pageCount;
                    _this.TreatedTotal = data.data.dataTotal;
                },
                function (error) {
                    console.log(error);
                });
        },
        FindHandling() {
            var _this = this;
            var item = _this.searchForm;
            if (_this.searchForm.datepicker != '') {
                item.startTime = _this.searchForm.datepicker[0] + ' 00:00:00';
                item.endTime = _this.searchForm.datepicker[1] + ' 23:59:59';
            }
            _this.Treated.Handling(_this.PageIndex, item.startTime, item.endTime, item.keyWord,
                function (data) {
                    _this.treatedtableData = data.data.results;
                    _this.PageIndex = data.data.pageIndex;
                    _this.PageSize = data.data.pageSize;
                    _this.PageTotal = data.data.pageCount;
                    _this.TreatedTotal = data.data.dataTotal;
                },
                function (error) {
                    console.log(error);
                });
        },
        FindFinish() {
            var _this = this;
            var item = _this.searchForm;
            if (_this.searchForm.datepicker != '') {
                item.startTime = _this.searchForm.datepicker[0] + ' 00:00:00';
                item.endTime = _this.searchForm.datepicker[1] + ' 23:59:59';
            }
            _this.Treated.Finish(_this.PageIndex, item.startTime, item.endTime, item.keyWord,
                function (data) {
                    _this.treatedtableData = data.data.results;
                    _this.PageIndex = data.data.pageIndex;
                    _this.PageSize = data.data.pageSize;
                    _this.PageTotal = data.data.pageCount;
                    _this.TreatedTotal = data.data.dataTotal;
                },
                function (error) {
                    console.log(error);
                });
        },
        setWidth() {
            switch (this.state) {
                case '99':
                    this.tableWidth = "300"
                    break;
                default:
                    this.tableWidth = "300"
                    break;
            }
        },
        handleClick(tab) {
            var _this = this;
            _this.PageIndex = 1;
            _this.setWidth()
            _this.treatedtableData = [];
            switch (tab.name) {
                case '99':
                    _this.FindAllTreated();
                    break;
                default:
                    _this.getTreated()
                    break;
            }
        },
        search() {
            var _this = this
            _this.PageIndex = 1;
            _this.treatedtableData = [];
            switch (_this.state) {
                case '99':
                    _this.FindAllTreated();
                    break;
                default:
                    _this.getTreated()
                    break;
            }
        },
        TransferDepartment(data) {
            var _this = this;
            _this.departmentName = this.$refs.myCascader.getCheckedNodes()[0].label;
            _this.DoctorData = [];
            _this.User.RefeAllUser(_this.departmentForm.hospitalId, data, function (data) {
                _this.DoctorData = data.data;
                _this.departmentForm.doctor = '';
            }, function (err) {
                _this.departmentForm.doctor = '';
                console.log(err);
            });
        },
        DepartmentBtn(index) {
            var _this = this;
            var Item = _this.treatedtableData[index];
            _this.departmentdialog = true;
            _this.departmentForm.id = Item.referralId;
            _this.departmentForm.urgent = 0;
            _this.departmentForm.reason = '';
            _this.departmentForm.content = '';
            _this.departmentForm.hospitalPhone = Item.receptionHospitalPhone;
            _this.departmentForm.hospitalId = Item.receptionHospitalId;
            _this.departmentForm.hospital = Item.receptionHospitalName;
            _this.departmentForm.department = '';
            _this.departmentForm.doctor = '';
            _this.Organization.Department(_this.departmentForm.hospitalId,
                function (data) {
                    _this.DepartmentData = data.data;
                    console.log(_this.DepartmentData);
                }, function (err) {
                    console.log(err);
                });
        },
        InfoDepartmentBtn(data) {
            var _this = this;
            _this.departmentdialog = true;
            _this.departmentForm.urgent = 0;
            _this.departmentForm.reason = '';
            _this.departmentForm.content = '';
            _this.departmentForm.id = data.referralId;
            _this.departmentForm.hospitalPhone = data.receptionHospitalPhone;
            _this.departmentForm.hospitalId = data.receptionHospitalId;
            _this.departmentForm.hospital = data.receptionHospitalName;
            _this.departmentForm.department = '';
            _this.departmentForm.doctor = '';
            _this.Organization.Department(_this.departmentForm.hospitalId,
                function (data) {
                    _this.DepartmentData = data.data;
                    console.log(_this.DepartmentData);
                }, function (err) {
                    console.log(err);
                });
        },
        SavaDepartment(formName) {
            var _this = this;
            var item = _this.departmentForm;
            var Doctor = _this.DoctorData.find(function (x) {
                return x.id === item.doctor
            })
            console.log(item)
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    _this.Treated.Transfer(item.id, parseInt(item.urgent), item.reason, item.content, item.doctor, item.hospitalId, _this.departmentForm.hospitalPhone, _this.departmentForm.hospital, _this.departmentName, Doctor.phone, Doctor.userName, function (data) {
                        _this.departmentdialog = false;
                        _this.PageIndex = 1;
                        _this.treatedtableData = [];
                        switch (_this.state) {
                            case '99':
                                _this.FindAllTreated();
                                break;
                            default:
                                _this.getTreated()
                                break;
                        }
                    },
                        function (err) {
                            console.log(err);
                        }
                    )
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });

        },
        ReferralBtn(index) {
            var _this = this;
            var Item = _this.treatedtableData[index];
            this.treateddialog = true;
            this.changeRadio();
            _this.treatedForm.id = Item.referralId;
            _this.treatedForm.urgent = 0;
            _this.treatedForm.reason = '';
            _this.treatedForm.content = '';
            _this.treatedForm.radio = 0;
            _this.treatedForm.otherHospital = '';
            _this.treatedForm.hospitalId = '';
            _this.treatedForm.departmentId = '';
            _this.treatedForm.doctorId = '';
        },
        changeRadio() {
            var _this = this;
            _this.DepartmentData = [];
            _this.DoctorData = [];
            _this.treatedForm.otherHospital = undefined
            _this.treatedForm.hospitalId = undefined
            _this.treatedForm.departmentId = undefined
            _this.treatedForm.doctorId = undefined

            // this.$refs['treatedForm'].resetFields();
            if (_this.treatedForm.radio == 0) {
                _this.Organization.getAllHospital(2,
                    function (data) {
                        _this.HospitalData = data.data;
                    }, function (err) {
                        console.log(err);
                    });
            }
        },
        finishRadio() {
            this.procesForm.hospital = '';
            this.procesForm.department = '';
            this.procesForm.doctor = '';
        },
        OtherHospital(data) {
            var _this = this;
            _this.DepartmentData = [];
            _this.DepartmentId = data;
            _this.Organization.Department(_this.DepartmentId, function (data) {
                _this.DepartmentData = data.data;
                _this.treatedForm.departmentId = '';
                _this.treatedForm.doctorId = '';
            }, function (err) { console.log(err); });
            return _this.DepartmentId;
        },
        OtherDepartment(data) {
            var _this = this;
            _this.departmentName = this.$refs.cascader.getCheckedNodes()[0].label;
            _this.DoctorData = [];
            var doctorId = data;
            _this.User.RefeAllUser(_this.DepartmentId, doctorId, function (data) {
                _this.DoctorData = data.data;
                _this.treatedForm.doctorId = '';
            }, function (err) {
                console.log(err);
            });
        },
        SavaTransfer(formName) {
            var _this = this;
            _this.PageIndex = 1;
            _this.treatedtableData = [];
            var item = _this.treatedForm;
            var Hospital = _this.HospitalData.find(function (x) {
                return x.id === item.hospitalId
            });
            var Doctor = _this.DoctorData.find(function (x) {
                return x.id === item.doctorId
            })
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    if (_this.treatedForm.radio == 0) {
                        _this.Treated.Transfer(item.id, item.urgent, item.reason, item.content, item.doctorId, item.hospitalId, Hospital.phone, Hospital.name, _this.departmentName, Doctor.phone, Doctor.userName, function (data) {
                            _this.treateddialog = false;
                            switch (_this.state) {
                                case '99':
                                    _this.FindAllTreated();
                                    break;
                                default:
                                    _this.getTreated()
                                    break;
                            }
                        },
                            function (err) {
                                console.log(err);
                            }
                        )
                    } else if ((_this.treatedForm.radio == 1)) {
                        _this.Treated.OtherTransfer(item.id, item.urgent, item.reason, item.content, item.radio, item.otherHospital, function (data) {
                            _this.treateddialog = false;
                            switch (_this.state) {
                                case '99':
                                    _this.FindAllTreated();
                                    break;
                                default:
                                    _this.getTreated()
                                    break;
                            }
                        },
                            function (err) {
                                console.log(err);
                            }
                        )
                    }
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        },
        selectHospital(data) {
            this.procesForm.hospital = data;
            var _this = this;
            _this.Organization.Department(data, function (data) {
                _this.DepartmentData = data.data;
            }, function (err) { console.log(err); });
        },
        selectDepartment(data) {
            var _this = this;

            _this.User.RefeAllUser(this.procesForm.hospital, data, function (data) {
                _this.DoctorData = data.data;
            }, function (err) {
                console.log(err);
            });
        },
        ProcesBtn(index) {
            var _this = this;
            var Item = _this.treatedtableData[index];
            _this.procesdialog = true;
            _this.procesForm.id = Item.referralId;
            _this.procesForm.content = '';
            _this.procesForm.radio = 1;
            _this.procesForm.hospital = '';
            _this.procesForm.department = '';
            _this.procesForm.doctor = '';
            _this.Organization.getAllHospital(
                Item.receptionHospitalId,
                function (data) {
                    _this.HospitalData = data.data;
                },
                function (err) {
                    console.log(err);
                }
            );

        },

        SaveProcesForm(formName) {
            var _this = this;
            _this.PageIndex = 1;
            _this.treatedtableData = [];
            var item = _this.procesForm
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    if (_this.procesForm.radio == 2) {
                        _this.Treated.FinishReferral2(item.id, item.content, parseInt(item.radio), item.hospital, item.doctor, function (data) {
                            _this.procesdialog = false;
                            switch (_this.state) {
                                case '99':
                                    _this.FindAllTreated();
                                    break;
                                default:
                                    _this.getTreated()
                                    break;
                            }
                        },
                            function (err) {
                                console.log(err);
                            }
                        )
                    } else {
                        _this.Treated.FinishReferral(item.id, item.content, parseInt(item.radio), function (data) {
                            _this.procesdialog = false;
                            switch (_this.state) {
                                case '99':
                                    _this.FindAllTreated();
                                    break;
                                default:
                                    _this.getTreated()
                                    break;
                            }
                        },
                            function (err) {
                                console.log(err);
                            }
                        )
                    }
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        },
        AddReferralBtn() {
            this.AddTreatedDrawer = true;
        },
        //接收
        ReceiveReferral(index) {
            var _this = this;
            var receiveItem = _this.treatedtableData[index];
            this.itemData = receiveItem;
            this.$confirm('确定接收此条转诊吗?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                _this.Treated.Receive(receiveItem.referralId, function (data) {
                    _this.addPatientForm(receiveItem);
                    // if (receiveItem.patientId && receiveItem.patientId) {
                    //     _this.goaddPatientForm(receiveItem);
                    //     _this.goSymptom();
                    // } else {
                    //     _this.addPatientForm(receiveItem);
                    // }
                    switch (_this.state) {
                        case '99':
                            _this.FindAllTreated();
                            break;
                        default:
                            _this.getTreated()
                            break;
                    }
                    _this.$message({
                        type: 'success',
                        message: '接收成功!'
                    });
                }, function (err) {
                    console.log(err);
                });
            }).catch(() => {
                _this.$message({
                    type: 'info',
                    message: '已取消接收'
                });
            });
        },
        //此方法是保存患者到列表里面没有patientId
        addPatientForm(receiveItem) {
            var _this = this;
            receiveItem.userId = _this.userInfo.id;
            receiveItem.PatientType = 1;
            const parmas = {
                ...receiveItem,
                idNo: receiveItem.identity,
                address: receiveItem.areaFullName,
                IsReferral: true
            }
            _this.patientDomain.patientAdd(
                parmas,
                function (data) {
                    _this.userReferralId = data.data;
                    _this.goSymptom();
                    // _this.getPatientListdata();
                    // if (_this.patientForm.diseaseName) {
                    //     _this.patientFormDes.past = _this.patientForm.diseaseName;
                    // }
                    // _this.patientDomain.GetPatientDetail(
                    //     data.data,
                    //     function (data) {
                    //         console.log('这个是什么');
                    //     },
                    //     function (error) {

                    //     }
                    // );
                },
                function (error) {
                    console.log(error);
                }
            );
        },
        //获取症状信息
        goSymptom() {
            var _this = this;
            this.patientDomain.GetPatientDetail(
                this.userReferralId,
                function (data) {
                    _this.patientDiagnose = data.data;
                    _this.alreadySubmitForm(false);
                    // if(data.data.patientDiagnose.length){
                    //     _this.patientDiagnose = data.data.patientDiagnose[0];
                    //     _this.getPicture(data.data.patientDiagnose[0].id)
                    // }else{
                    //     _this.patientDiagnose = data.data;
                    //     _this.alreadySubmitForm(false);
                    // }
                    
                },
                function (error) {
                    console.log(error);
                }
            );
        },
        //获取图片
        getPicture(str) {
            var _this = this;
            this.patientDomain.GetPatientDiagnoseDetail(
                str,
                function (data) {
                    _this.patientDetailAll = data.data;
                    _this.alreadySubmitForm(true);
                },
                function (error) {
                    console.log(error);
                }
            )
        },
        //暂存方法
        alreadySubmitForm(state) {
            var _this = this;
            if(state){
                _this.patientDomain.EditMZDDiagnose(
                    _this.patientDetailAll,
                function (data) {
                    
                },
                function (error) {
                    console.log(error);
                }
                );
            }else{
                const params = {
                    "main":"",
                    "family":"",
                    "height":0,
                    "heightTension":0,
                    "lowTension":0,
                    "breathe":0,
                    "pulse":0,
                    "temperature":0,
                    "weight":0,
                    "irritability":"",
                    "otherCheck":"",
                    "past":"",
                    "diseaseDiagnose":"",
                    "discriminatoryAnalysis":"",
                    "discriminatoryAnalysisList":[],
                    severity: this.itemData.severity,
                    "PatientId": this.patientDiagnose.id
                }
                _this.patientDomain.AddMZDDiagnose(
                    params,
                    function (data) {
                        _this.$router.push({
                            name:'interrogationIndex',
                            params: {
                                id: _this.userReferralId
                            }
                        })
                    },
                    function (error) {
                        console.log(error);
                    }
                );
            }
            
        },
        //有patientId的数据
        goaddPatientForm(receiveItem) {
            var _this = this;
            var params = {};
            params.userId = _this.userInfo.id;
            params.PatientType = 1;
            params.name = receiveItem.name;
            params.age = receiveItem.age;
            params.phone = receiveItem.phone;
            params.idNo = receiveItem.idNo;
            params.address = receiveItem.areaFullName;
            params.sex = receiveItem.sex;
            params.diseaseName = receiveItem.diseaseName;
            params.id = receiveItem.patientId;
            this.userReferralId = receiveItem.patientId;
            _this.patientDomain.patientEdit(
                params,
                function (data) {
                    console.log('请求成功了');
                },
                function (error) {
                    console.log(error);
                }
            );
        },
        // getPatientListdata() {
        //     var _this = this;
        //     _this.patientList = [];
        //     _this.patientDomain.getPatientList(
        //         _this.patientType,
        //         _this.organitionId,
        //         _this.userInfo.id,
        //         "",
        //         "",
        //         _this.patientKeyWord,
        //         _this.patientPageIndex,
        //         function (data) {
        //             _this.patientList = data.data.results;
        //             for (var i = 0; i < _this.patientList.length; i++) {
        //                 var f = _this.patientList[i].addTime.split("T");
        //                 _this.patientList[i].addTime = f[0] + " " + f[1].slice(0, 8);
        //             }
        //             _this.patientTotal = data.data.dataTotal;
        //             _this.gettWaitData();
        //         },
        //         function (error) {
        //             _this.gettWaitData();
        //             console.log(error);
        //         }
        //     );
        // },
        InfoReceiveReferral(data) {
            var _this = this;
            this.$confirm('确定接收此条转诊吗?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                _this.Treated.Receive(data, function (data) {
                    _this.$message({
                        type: 'success',
                        message: '接收成功!'
                    });
                    _this.reload();
                }, function (err) {
                    console.log(err);
                });
            }).catch(() => {
                _this.$message({
                    type: 'info',
                    message: '已取消接收'
                });
            });
        },
        cancelDialog(formName) {
            var _this = this;
            _this.treateddialog = false;
            _this.procesdialog = false;
            _this.departmentdialog = false;
            this.$refs[formName].resetFields();
        },
        onEditorChange({ editor, html, text }) {
            this.content = html;
        },
    },
    watch: {
        treatedFormDepartment() {
            if (this.$refs.cascader) {
                this.$refs.cascader.dropDownVisible = false
            }
        }
    },

}
</script>

<style scoped>
@import "../../assets/css/footer.css";

.center-content {
    box-sizing: border-box;
}

.iq-card-body {
    padding: 15px;
    border-radius: 15px;
    background: #FFFFFF;
}

.iq-card-body {
    padding: 15px;
    border-radius: 15px;
    background: #FFFFFF;
}


.iq-search-bar {
    display: inline-block;
    margin-right: 20px;
}

.iq-search-bar .searchbox {
    position: relative;
}

.iq-search-bar .searchbox .search-link {
    position: absolute;
    right: 15px;
    top: 2px;
    font-size: 16px;
}

.iq-search-bar .search-input {
    width: 100%;
    padding: 5px 40px 5px 15px;
    border-radius: 10px;
    border: none;
    background: #ceeede;
    line-height: 1.8;
    box-sizing: border-box;
}


.detail-tab {
    height: 70px;
    line-height: 70px;
    background: #FFFFFF;
    margin-bottom: 30px;
}

.detail-tab>li {
    width: 50%;
    display: inline-block;
    font-size: 24px;
    font-weight: bold;
    padding-left: 15px;
    box-sizing: border-box;
    cursor: pointer;
}

.detail-tab>li.active {
    background: #f8f8f8;
    box-shadow: 2px 0px 10px rgba(31, 183, 166, 0.19);
}

.detail-tab>li em {
    font-style: normal;
    color: #04a290;
}

.detail-tab>li .iconfont {
    font-size: 24px;
    color: #04a290;
    margin-right: 5px;
    font-weight: normal;
}

.detail-item {
    margin-bottom: 30px;
}

.detail-tit {
    padding-left: 20px;
    height: 48px;
    line-height: 48px;
    font-size: 16px;
    color: #FFFFFF;
    background: #1fb7a6;
}

.detail-info {
    padding: 10px 30px;
    background: #FFFFFF;
}

.detail-info ul li {
    padding: 5px 0;
    color: #333333;
    font-size: 15px;
}

.detail-info ul li span {
    display: inline-block;
    width: 100px;
    vertical-align: top;
}

.detail-info ul li i {
    display: inline-block;
    font-style: normal;
    width: calc(100% - 130px);
}

.btn-grounp {
    text-align: center;
}

.btn-grounp .el-button {
    width: 90px;
}

.no-data {
    height: 144px;
    text-align: center;
}

.no-data img {
    height: 100px;
}

.no-data p {
    color: #04a290;
}
</style>